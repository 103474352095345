<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-card>
        <v-card-title>{{model.id ? 'Изменить документ возврата' : 'Создать документ возврата'}}</v-card-title>
        <v-divider></v-divider>
        <div v-if="loading || savingReturn">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>
        <v-card-text v-else>
          <v-row>
            <v-col cols="12">
              <validation-provider v-slot="{errors}" name="counteragent" rules="required">
                <v-autocomplete v-model="model.counteragent"
                                :items="counteragents"
                                @change="getConsignmentsList"
                                item-value="id" item-text="full_name"
                                label="Контрагент"
                                :loading="counteragentsLoading"
                                required :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="8">
              <validation-provider v-slot="{errors}" name="doc" rules="required">
                <v-autocomplete v-if="model.counteragent"
                                v-model="model.doc"
                                :items="consignments"
                                :loading="!!fetchingConsignments"
                                @change="getConsignmentDetails"
                                item-value="id" item-text="label"
                                label="Фактура" required :error-messages="errors"
                                no-data-text="Ничего не найдено"
                ></v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="4">
              <validation-provider v-if="model.doc" v-slot="{errors}" name="reg_date" rules="required">
                <v-menu ref="consignmentDate" :disabled="regDateIsBlocked"
                        v-model="consignmentDateMenu"
                        :close-on-content-click="false"
                        max-width="290px" min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="model.reg_date|date" label="Дата" :disabled="regDateIsBlocked"
                                  prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="model.reg_date" @input="consignmentDateMenu = false"
                                 :allowed-dates="allowedRegDates" :disabled="regDateIsBlocked"
                  ></v-date-picker>
                </v-menu>
              </validation-provider>
            </v-col>

            <template v-if="model.doc">
              <div v-if="!!fetchingConsignmentDetails" class="ma-4 text-center">
                <v-progress-linear color="primary" indeterminate/>
              </div>
              <v-col v-else cols="12">
                <p>Товары для возврата: {{(model.consignments_elements || []).length}}</p>

                <template v-for="(item, index) in model.consignments_elements">
                  <v-row class="mt-0">
                    <v-col cols="7">
                      <span class="text-subtitle-1">{{item.product_name}}</span>
                    </v-col>
                    <v-col cols="5">
                      <validation-provider v-slot="{errors}" :name="`amount.${index}`" :rules="'required|maxOrZero:' + item.max_amount">
                        <v-text-field v-model.number="item.amount"
                                      required :error-messages="errors"
                                      class="pt-0"
                                      dense
                                      persistent-hint :hint="'Максимальное кол-во для возврата: ' + item.max_amount"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 mt-2 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!savingReturn"
                 :disabled="!!savingReturn || invalid || !model.reg_date"
                 rounded color="success"
          >
            <v-icon left>mdi-content-save</v-icon>
            {{model.id ? 'Сохранить' : 'Создать'}}
          </v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!savingReturn"
                 :loading="!!savingReturn"
                 rounded text color="error"
          >
            <v-icon left>mdi-cancel</v-icon>
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'
  import moment from 'moment'

  export default {
    name: 'consignment-return-form',

    data() {
      return {
        consignmentDateMenu: false,
        loading: true,
      }
    },

    computed: {
      consignments() {
        return ((this.consignmentsData || {}).results || []).map(c => {
          return {
            id: c.id,
            label: c.reg_number + ' от ' + this.$options.filters.date(c.reg_date)
          }
        })
      },

      ...mapState('Consignment', {
          consignmentsData: 'data',
          fetchingConsignments: 'fetching',
          fetchingConsignmentDetails: 'fetchingDetails',
          model: 'consignmentReturn',
          consignment: 'model',
      }),
      ...mapState('Consignment', ['savingReturn']),

      ...mapState('Counteragent', {
        counteragents: state => state.filterData?.results || [],
        counteragentsLoading: state => !!state.fetching,
      }),

      regDateIsBlocked() {
        return !this.userService.can('can_return_all_date')
      },
    },

    methods: {
      ...mapMutations('Consignment', [
        'setItems', 'updateReturn'
      ]),
      ...mapMutations('Consignment', {
        setConsignmentDetails: 'setModel'
      }),

      ...mapActions('Consignment', [
        'removeItem'
      ]),

      allowedRegDates(val) {
        return moment(val).isBetween(moment(this.consignment.reg_date), moment(), 'date', '[]')
      },

      getConsignmentsList() {
        if (this.model.counteragent) {
          this.updateReturn({doc: null, consignments_elements: []})
          this.$store.dispatch('Consignment/fetch', {counteragent: this.model.counteragent})
        } else {
          this.setItems(null)
          this.setConsignmentDetails({})
        }
      },

      getConsignmentDetails() {
        if (this.model.doc) {
          this.$store.dispatch('Consignment/generateReturnItems', this.model.doc)
        } else {
          this.updateReturn({consignments_elements: []})
        }
      },

      cancel(reset) {
        reset()
        this.$emit('consignment-return:form:cancel')
      },

      save() {
        this.$store
          .dispatch('Consignment/saveReturns')
          .then(() => {
            this.$emit('consignment-return:form:saved')
          })
      },
    },

    mounted() {
      Promise.all([
        this.$store.dispatch('Product/fetch'),
      ]).then(() => this.loading = false)
    }
  }
</script>
