<template>
  <v-container fluid>
    <v-row ref="filtersContainer" class="mb-1">
      <v-col cols="12" class="d-flex align-center pb-0">
        <span class="text-h5">Список платежей</span>
        <v-dialog v-model="formDialog" width="70vw" persistent>
          <template #activator="{on, attrs}">
            <v-btn class="ml-4" style="vertical-align: middle;" rounded color="primary"
                   v-bind="attrs" v-on="on" small
            >
              <v-icon left>mdi-plus</v-icon>
              Добавить
            </v-btn>
          </template>
          <payment-form v-if="formDialog"
                        @payment:form:saved="closeForm"
                        @payment:form:cancel="closeForm"
          ></payment-form>
        </v-dialog>
      </v-col>
      <v-col cols="2">
        <v-select v-model="filter.cash_type"
                  :items="PaymentForms"
                  item-value="id" item-text="name"
                  prepend-inner-icon="mdi-cash-check" label="Форма оплаты" clearable hide-details
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-menu v-model="fromDateMenu" transition="scale-transition" offset-y max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="fromDateFormatted"
                          label="с" prepend-inner-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.reg_date_after" :disabled="!!fetching"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-menu v-model="toDateMenu" transition="scale-transition" offset-y max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="toDateFormatted"
                          label="по" prepend-inner-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.reg_date_before" :disabled="!!fetching"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-select v-model="filter.type"
                  :items="PaymentTypes"
                  item-value="id" item-text="altName2"
                  label="Тип платежа" clearable
                  @change="resetSources()" @click:clear="resetSources()" hide-details
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-select v-model="filter.source" :items="SourceTypes.getFor(filter.type)"
                  item-value="id" item-text="name"
                  label="Тип объекта" clearable
                  @change="resetSourceId()" @click:clear="resetSourceId()" hide-details
        ></v-select>
      </v-col>
      <v-col v-if="filter.source && getSourceType(filter.source).hasData" cols="2">
        <v-autocomplete v-model="object" :items="objects.data"
                        item-value="id" item-text="full_name"
                        :label="objectLabel" prepend-inner-icon="mdi-view-dashboard"
                        :loading="!!objects.loading" :disabled="!!fetching"
                        clearable @click:clear="object = null" hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-row class="font-weight-bold text-left blue-grey lighten-5 pb-5">
          <v-col class="pb-0" cols="1">Наличными:</v-col>
          <v-col class="pb-0" cols="3">
            <div class="success--text text--darken-3">Приход: {{paymentTotals.uzs_cash_debet_total|money}}</div>
            <div class="error--text text--darken-2">Расход: {{paymentTotals.uzs_cash_credit_total|money}}</div>
          </v-col>
          <v-col class="pb-0" cols="2">
            <div class="success--text text--darken-3">Приход: {{paymentTotals.usd_cash_debet_total|money('usd')}}</div>
            <div class="error--text text--darken-2">Расход: {{paymentTotals.usd_cash_credit_total|money('usd')}}</div>
          </v-col>
          <v-col class="pb-0" cols="1">Перечисл.:</v-col>
          <v-col class="pb-0" cols="3">
            <div class="success--text text--darken-3">Приход: {{paymentTotals.uzs_transfer_debet_total|money}}</div>
            <div class="error--text text--darken-2">Расход: {{paymentTotals.uzs_transfer_credit_total|money}}</div>
          </v-col>
          <v-col class="pb-0" cols="2">
            <div class="success--text text--darken-3">Приход: {{paymentTotals.usd_transfer_debet_total|money('usd')}}</div>
            <div class="error--text text--darken-2">Расход: {{paymentTotals.usd_transfer_credit_total|money('usd')}}</div>
          </v-col>
          <v-col class="pt-0" cols="3" offset="1">
            <div>Итог: {{paymentTotals.uzs_cash_debet_total - paymentTotals.uzs_cash_credit_total || 0 | money}}</div>
            <div class="mt-2 v-divider">Всего: {{paymentTotals.cash_total || 0 | money}}</div>
          </v-col>
          <v-col class="pt-0" cols="2">
            <div>Итог: {{(paymentTotals.usd_cash_debet_total - paymentTotals.usd_cash_credit_total) || 0|money('usd')}}</div>
          </v-col>
          <v-col class="pt-0" cols="3" offset="1">
            <div>Итог: {{(paymentTotals.uzs_transfer_debet_total - paymentTotals.uzs_transfer_credit_total || 0).toFixed(2) | money}}</div>
            <div class="mt-2 v-divider">Всего: {{paymentTotals.transfer_total || 0|money}}</div>
          </v-col>
          <v-col class="pt-0" cols="2">
            <div>Итог: {{(paymentTotals.usd_transfer_debet_total - paymentTotals.usd_transfer_credit_total) || 0|money('usd')}}</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-data-table
      :headers="nonSortableHeaders"
      :items="payments"
      :loading="!!fetching" :items-per-page="filter.limit"
      :height="dataTableHeight" disable-items-per-page hide-default-footer fixed-header fixed-header
      no-data-text="Нет данных" loading-text="Данные загружаются" class="mt-3"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{ filter.limit * (filter.page - 1) + index + 1 }}</td>
          <td>{{ item.reg_number }}</td>
          <td>{{ item.reg_date|date }}</td>
          <td>
            <span :class="getPaymentType(item.type).color2 + '--text'">{{ (getPaymentType(item.type) || {}).altName2 }}</span>
          </td>
          <td>{{getPaymentForm(item.cash_type).name}}</td>
          <td>
            <span v-if="guessSource(item) === 'counteragent'">
              {{item.source_object_info.full_name }}
            </span>
            <span v-if="guessSource(item) === 'staff_salary'">
              {{ getStaff(item.staff) || {} |full_name }}
            </span>
            <span v-if="guessSource(item) === 'capital'">
              {{ getOwner(item.owner) || {}|full_name }}
            </span>
            <span v-if="guessSource(item) === 'transport_expenses'">
              {{(getTransport(item.transport) || {}).number }} - {{ (getTransport(item.transport) || {}).model}}
            </span>
            <span v-else>
              {{getSourceType(item.source).name}}
            </span>
          </td>
          <td>{{item.total|money(item.currency)}}</td>
          <td class="text-right">
            <v-btn class="ml-2" text small depressed color="success" @click="showDetails(item)">
              <v-icon left small>mdi-eye</v-icon>
              Показать
            </v-btn>
            <v-btn class="ml-2" icon small depressed color="error" @click="askRemove(item)"
                   :loading="deleting[item.id]"
            ><v-icon small>mdi-delete</v-icon></v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <pagination ref="paginationContainer" :limit.sync="limit" :page.sync="page" :total-pages="totalPages"></pagination>

    <v-dialog v-if="detailDialog" v-model="detailDialog" width="70vw" persistent>
      <payment-details @dialog:close="closeDetails"/>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapState as mapStateDefault, mapGetters, createNamespacedHelpers} from 'vuex'

const {mapState, mapActions, mapMutations} = createNamespacedHelpers('Payment')

import PaymentDetails from '@/modules/payment/details'
import PaymentForm from '@/modules/payment/form'
import moment from 'moment'
import {USER_DATE_FORMAT, SERVER_DATE_FORMAT} from '@/filters'
import {hasFilter, hasDialog} from '@/mixins'

export default {
  name: 'payment-list',

  mixins: [hasFilter, hasDialog],

  refocusable: {
    reg_number: 'reg_number_filter',
  },

  customFilters: {
    type: null,
    reg_date_after: '2022-01-01',
    reg_date_before: moment().format(SERVER_DATE_FORMAT),
    reg_number: '',
    cash_type: '',
    source: null,
    counteragent: null,
    staff: null,
    owner: null,
    transport: null,
  },

  created() {
    if (this.$route.params.id) {
      this.showDetails(this.$route.params)
    }
    this.fetch(this.filter)
  },

  computed: {
    ...mapState([
      'fetching',
      'data',
      'deleting',
      'PaymentTypes',
      'PaymentForms',
      'SourceTypes'
    ]),

    ...mapStateDefault('Counteragent', {
      counteragents: state => state.filterData?.results || [],
      counteragentsLoading: state => !!state.fetching,
    }),
    ...mapStateDefault('Staff', {
      staff: state => (state.data?.results || []).map(s => {
        s.full_name = `${s.last_name} ${s.first_name} ${s.middle_name}`
        return s
      }),
      staffLoading: state => !!state.fetching,
    }),
    ...mapStateDefault('Owner', {
      owner: state => (state.data?.results || []).map(s => {
        s.full_name = `${s.last_name} ${s.first_name} ${s.middle_name}`
        return s
      }),
      ownerLoading: state => !!state.fetching,
    }),
    ...mapStateDefault('Transport', {
      transport: state => (state.data?.results || []).map(s => {
        s.full_name = `${s.number} - ${s.model}`
        return s
      }),
      transportLoading: state => !!state.fetching,
    }),
    ...mapGetters('Staff', ['getStaff']),
    ...mapGetters('Owner', ['getOwner']),
    ...mapGetters('Transport', ['getTransport']),
    ...mapGetters('Payment', ['getSourceType', 'getPaymentType']),

    headers() {
      return [
        {text: '#', align: 'center', width: '100'},
        {text: 'Номер регистрации', width: '150px'},
        {text: 'Дата'},
        {text: 'Тип операции', width: '120px'},
        {text: 'Форма', width: '100px'},
        {text: 'Тип'},
        {text: 'Сумма'},
        {text: 'Действия', align: 'right'},
      ]
    },

    payments() {
      return this.data?.results || []
    },

    paymentTotals() {
      return this.data?.total || {}
    },

    fromDateFormatted() {
      return moment(this.filter.reg_date_after).format(USER_DATE_FORMAT)
    },

    toDateFormatted() {
      return moment(this.filter.reg_date_before).format(USER_DATE_FORMAT)
    },


    objects() {
      switch (this.filter.source) {
        case 'counteragent':
          return {data: this.counteragents, loading: this.counteragentsLoading, title: 'full_name', field: 'counteragent'}
        case 'staff_salary':
          return {data: this.staff, loading: this.staffLoading, title: 'full_name', field: 'staff'}
        case 'capital':
          return {data: this.owner, loading: this.ownerLoading, title: 'full_name', field: 'owner'}
        case 'transport_expenses':
          return {data: this.transport, loading: this.transportLoading, title: 'full_name', field: 'transport'}
        default:
          return {}
      }
    },

    object: {
      get() {
        return this.filter[this.objects.field]
      },
      set(val) {
        this.filter[this.objects.field] = val
        this.resetSourceId(this.SourceTypes.getIds().filter(f => f !== this.objects.field))
      }
    },

    objectLabel() {
      return (this.SourceTypes.find(s => s.id === this.filter.source) || {}).object_label
    }
  },

  methods: {
    resetSources() {
      this.filter.source = null
      this.resetSourceId()
    },

    resetSourceId(fields) {
      (fields || this.SourceTypes.getIds()).forEach(field => {
        this.filter[field] = null
      })
    },

    ...mapActions(['fetch', 'show', 'remove']),
    ...mapMutations(['setModel']),

    getPaymentForm(val) {
      return this.PaymentForms.find(p => p.id === val)
    },

    guessSource(item) {
      return item.source || (item.counteragent && 'counteragent') || (item.staff && 'staff_salary') || (item.capital && 'capital')
    },
  },

  mounted() {
    this.$eventBus.$emit('setTitle', 'Список платежей')
    this.$store.dispatch('Counteragent/fetchFilterList')
    this.$store.dispatch('Staff/fetch')
    this.$store.dispatch('Owner/fetch')
    this.$store.dispatch('Transport/fetch')
  },

  components: {
    PaymentForm,
    PaymentDetails,
  }
}
</script>
