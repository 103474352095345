<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-card>
        <v-card-title>{{model.id ? 'Изменить платеж' : 'Создать платеж'}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text v-show="showTypeSelector && !model.id" class="text-center">
          <h2 class="my-5">Выберите тип платежа</h2>
          <v-btn color="success" large class="mx-3"
                 @click="model.type = 'debet'; showTypeSelector = false"
          >
            <v-icon left>mdi-download-multiple</v-icon> Приход
          </v-btn>
          <v-btn color="error" large class="mx-3"
                 @click="model.type = 'credit'; showTypeSelector = false"
          >
            <v-icon left>mdi-upload-multiple</v-icon> Расход
          </v-btn>
        </v-card-text>
        <v-card-text v-show="!showTypeSelector || model.id">
          <v-row>
            <v-col cols="3">
                <validation-provider v-slot="{errors}" name="reg_date" rules="required|max:256">
                  <v-menu ref="paymentDate" v-model="paymentDateMenu"
                          :disabled="paymentDateIsBlocked"
                          :close-on-content-click="false" max-width="290px" min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :value="model.reg_date|date" label="Дата"
                                    :disabled="paymentDateIsBlocked"
                                    prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="model.reg_date"
                                   @input="paymentDateMenu = false"
                                   :allowed-dates="futureDates"
                    ></v-date-picker>
                  </v-menu>
                </validation-provider>
            </v-col>
            <v-col cols="4">
              <validation-provider v-slot="{errors}" name="source" rules="required">
                <v-select v-model="model.source"
                          @change="resetSourceId"
                          :disabled="!!model.id"
                          :items="SourceTypes.getFor(model.type)"
                          item-value="id" item-text="name"
                          required :error-messages="errors"
                          label="Тип"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="5">
              <validation-provider v-if="model.source && objects.hasData" v-slot="{errors}" name="object" rules="required|integer">
                <v-autocomplete v-model="object"
                                :disabled="!!model.id"
                                :items="objects.data"
                                :loading="objects.loading"
                                item-value="id" :item-text="objects.title"
                                required :error-messages="errors"
                                :label="objectLabel"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="6" v-show="false">
              <validation-provider v-slot="{errors}" name="type" rules="required|max:256">
                <v-select v-model="model.type"
                          :items="PaymentTypes"
                          item-value="id" item-text="name"
                          required :error-messages="errors"
                          label="Тип"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="4">
              <validation-provider v-slot="{errors}" name="cash_type" rules="required">
                <v-select v-model="model.cash_type"
                          :items="PaymentForms"
                          item-value="id" item-text="name"
                          required :error-messages="errors"
                          label="Форма платежа"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="2">
              <validation-provider v-slot="{errors}" name="currency" rules="required">
                <v-select v-model="model.currency"
                          :items="currencies" label="Валюта"
                          item-value="id" item-text="format"
                          required :error-messages="errors"
                          @change="setCrossCurrency"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="total" rules="required|positive|double">
                <v-text-field v-model="model.total"
                              @input="setCrossAmount"
                              label="Сумма"
                              :error-messages="errors" required
                ></v-text-field>
              </validation-provider>
            </v-col>
            <template v-if="isCrossCurrencyEnabled">
              <v-col cols="4">
                <v-checkbox v-model="model.cross_currency" color="success" label="Кросс-валютная операция" hide-details
                            @change="setCrossRate"
                            :disabled="!isCrossCurrencyEnabled"
                ></v-checkbox>
              </v-col>
              <template v-if="model.cross_currency">
                <v-col cols="4">
                  <validation-provider v-slot="{errors}" name="cross_rate" rules="required|double">
                    <v-text-field v-model="model.cross_currency_data.rate"
                                  @input="setCrossAmount"
                                  required :error-messages="errors"
                                  label="Курс обмена"
                                  :suffix="getCurrency(model.cross_currency_data.cross_currency).format"
                                  :hint="currencyHint" persistent-hint
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="4">
                  <validation-provider v-slot="{errors}" name="cross_currency_data_amount" rules="required">
                    <v-text-field :value="model.cross_currency_data.cross_currency_amount | money(model.cross_currency_data.cross_currency, true)"
                                  label="Получаемая сумма" disabled required :error-messages="errors"
                                  :suffix="getCurrency(model.cross_currency_data.cross_currency).format"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </template>
            </template>
            <v-col cols="12">
              <validation-provider v-slot="{errors}" name="comments" rules="max:512">
                <v-textarea v-model="model.comments" label="Комментарий"></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving"
                 :disabled="!!saving || invalid"
                 rounded color="success"
          ><v-icon left>mdi-content-save</v-icon> {{model.id ? 'Сохранить' : 'Создать'}}</v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 :loading="!!saving"
                 rounded text color="error"
          ><v-icon left>mdi-cancel</v-icon> Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
  import {mapState, mapGetters} from 'vuex'
  import moment from 'moment'
  import hasCurrency from '@/mixins/hasCurrency'

  export default {
    name: 'payment-form',

    mixins: [
      hasCurrency,
    ],

    data() {
      return {
        showTypeSelector: true,
        paymentDateMenu: false,
      }
    },

    computed: {
      paymentDateIsBlocked() {
        return !this.userService.can('payment_all_date')
      },
      isCrossCurrencyEnabled() {
        return this.model.source === 'counteragent'
      },
      currencyHint() {
        return `1$ = ${this.$options.filters.money(this.currentExchangeRate.rate, 'uzs')}`
      },

      ...mapState('Payment', ['PaymentTypes', 'PaymentForms', 'SourceTypes', 'saving', 'model']),
      ...mapGetters('Payment', ['getSourceType']),

      ...mapState('Counteragent', {
        counteragents: state => state.filterData?.results || [],
        counteragentsLoading: state => !!state.fetching
      }),

      ...mapState('Staff', {
        staff: state => (state.data?.results || []).map(s => {
          s.full_name = `${s.last_name} ${s.first_name} ${s.middle_name}`
          return s
        }),
        staffLoading: state => !!state.fetching
      }),

      ...mapState('Owner', {
        owner: state => (state.data?.results || []).map(s => {
          s.full_name = `${s.last_name} ${s.first_name} ${s.middle_name}`
          return s
        }),
        ownerLoading: state => !!state.fetching
      }),

      ...mapState('Transport', {
        transport: state => (state.data?.results || []).map(s => {
          s.full_name = `${s.number} - ${s.model}`
          return s
        }),
        transportLoading: state => !!state.fetching
      }),

      objects() {
        let hasData = this.getSourceType(this.model.source).hasData
        switch (this.model.source) {
          case 'counteragent':
            return {hasData, data: this.counteragents, loading: this.counteragentsLoading, title: 'full_name', field: 'counteragent'}
          case 'staff_salary':
            return {hasData, data: this.staff, loading: this.staffLoading, title: 'full_name', field: 'staff'}
          case 'capital':
            return {hasData, data: this.owner, loading: this.ownerLoading, title: 'full_name', field: 'owner'}
          case 'transport_expenses':
            return {hasData, data: this.transport, loading: this.transportLoading, title: 'full_name', field: 'transport'}
          default:
            return {hasData, data: null}
        }
      },

      object: {
        get() {
          return this.model[this.objects.field]
        },
        set(val) {
          this.model[this.objects.field] = val
        }
      },

      objectLabel() {
        return (this.SourceTypes.find(s => s.id === this.model.source) || {}).object_label
      },
    },

    methods: {
      setCrossCurrency(val) {
        this.model.cross_currency_data.main_currency = this.model.currency
        this.model.cross_currency_data.cross_currency = this.currencies.find(c => c.id !== this.model.currency).id
      },

      setCrossAmount() {
        this.model.cross_currency_data.amount = (parseFloat(this.model.total) || 0)

        let val
        if (this.model.cross_currency_data.main_currency === 'uzs') {
          val = this.model.cross_currency_data.amount / (parseFloat(this.model.cross_currency_data.rate) || 1)
        } else {
          val = this.model.cross_currency_data.amount * (parseFloat(this.model.cross_currency_data.rate) || 1)
        }

        this.model.cross_currency_data.cross_currency_amount = val
      },

      setCrossRate() {
        this.model.cross_currency_data.rate = parseFloat(this.currentExchangeRate.rate) || 1
        this.setCrossAmount()

        if (!this.model.cross_currency) {
          this.$store.commit('Payment/setCrossCurrencyData')
        }
      },

      resetSourceId() {
        this.model.cross_currency = false
        this.SourceTypes.getIds().forEach(field => {
          this.model[field] = null
        })
      },

      cancel(reset) {
        reset()
        this.$emit('payment:form:cancel')
      },

      save() {
        this.$store
          .dispatch('Payment/save')
          .then(() => {
            this.$emit('payment:form:saved')
          })
      },

      futureDates(val) {
        return moment().isAfter(moment(val))
      },
    },

    mounted() {
    }
  }
</script>
