<template>
  <v-form>
    <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
      <v-card>
        <v-card-title>
          {{model.id ? 'Изменить документ продажи' : 'Создать документ продажи'}}
          <v-spacer/>
          <span v-if="model.counteragent && !loading" :class="counteragentWithTotals.calculation_totals > 0 ? 'success--text text--darken-3' : 'error--text text--darken-2'">
            <template v-if="counteragentWithTotalsLoading">
              <v-progress-circular width="2" indeterminate></v-progress-circular>
            </template>
            <span v-else>
              <span v-if="counteragentWithTotals.calculation_totals < 0">Мы должны:</span>
              <span v-else>Нам должны:</span>
              {{ counteragentWithTotals.calculation_totals|money }}
            </span>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <div v-if="loading || fetching">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>
        <v-card-text v-else>
          <v-row>
            <v-col cols="3">
              <validation-provider v-slot="{errors}" name="reg_date" rules="required|max:256">
                <v-menu ref="consignmentDate" :disabled="regDateIsBlocked"
                        v-model="consignmentDateMenu"
                        :close-on-content-click="false"
                        max-width="290px" min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :value="model.reg_date|date" label="Дата" :disabled="regDateIsBlocked"
                                  prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="model.reg_date" @input="consignmentDateMenu = false"
                                 :allowed-dates="allowedRegDates" :disabled="regDateIsBlocked"
                  ></v-date-picker>
                </v-menu>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="counteragent" rules="required|max:256">
                <v-autocomplete v-model="model.counteragent"
                                :items="counteragents"
                                item-value="id" item-text="full_name"
                                label="Контрагент"
                                :loading="counteragentsLoading"
                                required :error-messages="errors"
                                @change="getCalculationTotals"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <v-select v-model="model.currency" class="inner-elevation-0"
                        :items="currencies" label="Валюта"
                        item-value="id" item-text="title" hide-details
                        @change="updateItemPrices"
              ></v-select>
            </v-col>

            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="driver" rules="max:100">
                <v-text-field v-model="model.non_staff_driver"
                              required :error-messages="errors"
                              label="Водитель"
                ></v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="6">
              <validation-provider v-slot="{errors}" name="truck" rules="max:100">
                <v-text-field v-model="model.non_company_transport"
                              required :error-messages="errors"
                              label="Инфо о транспорте"
                ></v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="9">
              <p>Элементы (всего позиций: {{(consignmentItems || []).length}})</p>

              <template v-for="(item, index) in consignmentItems">
                <v-row>
                  <v-col cols="6">
                    <validation-provider v-slot="{errors}" :name="`product_type.${index}`" rules="required|double">
                      <v-autocomplete v-model="item.product_type"
                                      :items="products"
                                      :loading="productsLoading"
                                      @change="setItemDefaults(index)"
                                      item-value="id" item-text="name"
                                      required :error-messages="errors"
                                      label="Продукт" dense
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="1">
                    <validation-provider v-slot="{errors}" :name="`amount.${index}`" rules="required|integer">
                      <v-text-field v-model.number="item.amount"
                                    @keyup="setItemPrice(index)"
                                    required :error-messages="errors"
                                    label="Кол-во" dense
                      ></v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="2">
                    <validation-provider v-slot="{errors}" :name="`price.${index + 1}`"  rules="required|double">
                      <v-text-field v-model.number="item.price" :suffix="priceSuffix"
                                    @keyup="setItemPrice(index)"
                                    required :error-messages="errors"
                                    label="Цена" dense
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field v-model="item.total" readonly :suffix="priceSuffix"
                                  append-outer-icon="mdi-close" label="Всего" dense
                                  @click:append-outer="removeItem(index)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>

              <v-btn @click="createItem" color="primary" small
                :loading="productsLoading" :disabled="productsLoading"
              >
                <v-icon small left>mdi-plus</v-icon> Добавить продукт
              </v-btn>
            </v-col>
            <v-col cols="3" class="text-right">
              <h4>Промежуточный итог:</h4>
              <span class="display-1">{{rawTotal|money(model.currency)}}</span>
              <h4 class="mt-3">Итого:</h4>
              <span class="display-1">{{total|money(model.currency)}}</span>
              <div v-if="total - rawTotal" class="success--text">
                {{parseFloat((total - rawTotal).toFixed(this.pricePrecision))|money(model.currency)}}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-space-between pb-4 mt-2 px-4">
          <v-btn @click="handleSubmit(save)"
                 :loading="!!saving"
                 :disabled="!!saving || invalid"
                 rounded color="success"
          >
            <v-icon left>mdi-content-save</v-icon>
            {{model.id ? 'Сохранить' : 'Создать'}}
          </v-btn>
          <v-btn @click="cancel(reset)"
                 :disabled="!!saving"
                 :loading="!!saving"
                 rounded text color="error"
          >
            <v-icon left>mdi-cancel</v-icon>
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-form>
</template>

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'
  import moment from 'moment'

  export default {
    name: 'consignment-form',

    data() {
      return {
        consignmentDateMenu: false,
        loading: true,
      }
    },

    computed: {
      ...mapState('Consignment', ['saving', 'fetching', 'model', 'consignmentItems']),

      ...mapState('Counteragent', {
        counteragents: state => state.filterData?.results || [],
        counteragentsLoading: state => !!state.fetching,
        counteragentWithTotals: state => state.modelWithTotals || {},
        counteragentWithTotalsLoading: state => !!state.fetchingDetails,
      }),

      ...mapGetters('Counteragent', ['getFilterCounteragent']),

      ...mapState('Product', {
        products: state => (state.data?.results || []),
        productsLoading: state => !!state.fetching,
      }),
      ...mapGetters('Product', ['getProduct']),

      ...mapState('CurrencyRate', {
        currencies: state => state.Currencies,
      }),
      ...mapGetters('CurrencyRate', ['getCurrency']),

      total() {
        return (this.consignmentItems || []).reduce((total, ti) => total + (ti.amount || 0) * (ti.price || 0), 0).toFixed(this.pricePrecision)
      },

      rawTotal() {
        return (this.consignmentItems || []).reduce((total, ti) => total + (ti.amount || 0) * (ti.price || 0), 0).toFixed(this.pricePrecision)
      },

      regDateIsBlocked() {
        return moment(this.model.reg_date).isBefore(moment().startOf('day')) || !this.userService.can('can_consignment_all_date')
      },

      priceField() {
        return this.model.currency === 'usd' ? 'sell_price_usd' : 'sell_price'
      },

      pricePrecision() {
        return this.getCurrency(this.model.currency).precision
      },

      priceSuffix() {
        return this.getCurrency(this.model.currency).format
      }
    },

    methods: {
      ...mapMutations('Consignment', [
        'createItem', 'setModel', 'setItem', 'updateModel'
      ]),

      ...mapActions('Consignment', [
        'removeItem',
      ]),

      ...mapActions('Counteragent', ['showWithTotals']),
      getCalculationTotals(counteragentId) {
        return this.showWithTotals(counteragentId)
      },

      allowedRegDates(val) {
        return moment().isAfter(moment(val))
      },

      setItemDefaults(index) {
        let item = this.consignmentItems[index]
        item.id = null
        item.price = parseFloat(((this.getProduct(item.product_type) || {})[this.priceField] || 0).toFixed(this.pricePrecision))
        item.total = parseFloat((item.price * item.amount || 0).toFixed(this.pricePrecision))
        this.setItem({index, item})
      },

      setItemPrice(index) {
        let item = this.consignmentItems[index]
        item.total = parseFloat((item.amount * item.price).toFixed(this.pricePrecision))

        this.setItem({index, item})
      },

      updateItemPrices() {
        this.$nextTick(() => this.consignmentItems.forEach((item, i) => {
          item.price = parseFloat((this.getProduct(item.product_type)[this.priceField] || 0).toFixed(this.pricePrecision))
          this.setItemPrice(i)
        }))
      },

      cancel(reset) {
        reset()
        this.$emit('consignment:form:cancel')
      },

      save() {
        this.$store
          .dispatch('Consignment/save')
          .then(() => {
            this.$emit('consignment:form:saved')
          })
      },
    },

    mounted() {
      Promise.all([
        this.$store.dispatch('Product/fetch'),
      ]).then(() => {
        this.loading = false
        if (this.model.counteragent) {
          this.getCalculationTotals(this.model.counteragent)
        }
      })
    }
  }
</script>
